import React from "react";
import ResourceTable from "./../components/ResourceTable.js";

const styledInfo = {
  paddingLeft: "20%",
  paddingRight: "20%",
};

const tablePadding = {
  paddingLeft: "9%",
  paddingRight: "9%",
};

const Resources = () => {
  return (
    <>
      <div id="top" style={styledInfo} className="my-5">
        <center>
          <p className="lead">
            On this page you will find a curated list of guides, articles, and
            other learning resources made by members of the community. They are
            sorted by relevance to individual characters, or general relevance,
            and you can further filter by tags for specific subjects, such as
            individual game mechanics, punishes, techs, matchups, and so on. You
            can also search via name or author. Clicking on a resource link will
            open it in a new tab, and whilst we can’t guarantee complete
            accuracy of external guides, we periodically vet them and prune
            resources that are outdated or inaccurate. In some cases we have
            added notes if a small part of a resource is outdated.
            <br />
            <br />
            If you have made a guide or learning tool, or discovered a tech, and
            would like it to be listed here, please use the appropriate channel
            on our{" "}
            <a
              className="styledLinks"
              style={{ textDecoration: "none"}}
              href="https://discord.gg/Ej7Wk4mMxh"
              target="_blank" rel="noreferrer"
            >
              discord server
            </a>{" "}
            and we will assess it for inclusion.
          </p>
        </center>
      </div>
      <div id="Resources" className="table-responsive" style={tablePadding}>
        <ResourceTable />
      </div>
    </>
  );
};

export default Resources;
