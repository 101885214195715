import Navbar from "react-bootstrap/Navbar";
import "./NavigationBar.css";
import { Container, Offcanvas, Nav } from "react-bootstrap";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const NavigationBar = () => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const URLParams = new URLSearchParams(location?.search)
  const [characterName, setCharacterName] = React.useState("");
 
  const getCharacterName = (characterId) => {
    fetch(
      `https://forhonorinfohub.com/api/CharacterName?characterId=${characterId}`
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        setCharacterName(myJson[0].name);
      });
  };

  React.useEffect(() => {
    if (location?.pathname === "/character") {
      const characterId = URLParams.get("");
      getCharacterName(characterId);
    }
  }, [location?.pathname]);
  const BasicModal = () => (
    <div>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Info / Credits
          {handleClose ? (
            <IconButton
            className="NavbarCloseButton"  
            aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 11,
                }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            This version of the infohub was created by{" "}
            <a
              className="styledLinks"
              href="https://twitter.com/NutellaFH"
              target="_blank" rel="noreferrer"
            >
              Nutella
            </a>
            {" "}with help from <a
              className="styledLinks"
              href="https://www.reddit.com/user/The_Filthy_Spaniard"
              target="_blank" rel="noreferrer"
            >
              Spaniard
            </a>. You can also reach me on discord, via the{" "}
            <a
              className="styledLinks"
              href="https://discord.gg/Ej7Wk4mMxh"
              target="_blank" rel="noreferrer"
            >
              discord server
            </a>
            . My username being "nutellaa.". Spaniard is also a point of
            contact, with his username being "thefilthyspaniard"
            <br />
            <br />
          </Typography>
          <Typography gutterBottom>
            <b>To add to your phone's home screen:</b>
          </Typography>
          <Typography gutterBottom>
            Android — tap '⋮' and select 'Add to Home Screen'
            <br />
            iOS — tap the Share Icon and select 'Add to Home Screen'
            <br />
            <br />
          </Typography>
          <Typography gutterBottom>
            <b>Credits:</b>
          </Typography>
          <Typography gutterBottom>
            <a
              className="styledLinks"
              href="https://www.reddit.com/user/The_Filthy_Spaniard"
              target="_blank" rel="noreferrer"
            >
              The Filthy Spaniard
            </a>{" "}
            - maintaining and updating the spreadsheet version of the infohub
            for a very long time. Helped with many aspects of the new infohub, they are my partner on the project.
            <br />
            <a
              className="styledLinks"
              href="https://www.youtube.com/user/fkkfreeze"
              target="_blank" rel="noreferrer"
            >
              Freeze
            </a>{" "}
            - supporting us along the way, as well as providing much of the information
            that is hosted on the website.
            <br />
            Barace - the man behind the original idea of the spreadsheet version
            of the infohub.
            <br />
            <a
              className="styledLinks"
              href="https://greenmoriyama.wordpress.com/"
              target="_blank" rel="noreferrer"
            >
              GreenMoriyama
            </a>{" "}
            - Lots of fact checking, corrections and maintaining the spreadsheet
            version of the infohub.
            <br />
            AchPhoenix - lots of testing help, corrections, and for being my{" "}
            <a
              className="styledLinks"
              href="https://en.wikipedia.org/wiki/Rubber_duck_debugging"
              target="_blank" rel="noreferrer"
            >
              rubber duck.
            </a>
            <br />
            <a
              className="styledLinks"
              href="https://www.reddit.com/user/PaulBury"
              target="_blank" rel="noreferrer"
            >
              PaulBury
            </a> - Helped us with some of the art the property and feat icons.
            <br />
            <a
              className="styledLinks"
              href="https://www.ubisoft.com/en-gb/game/for-honor"
              target="_blank" rel="noreferrer"
            >
              Ubisoft
            </a> - for making the game (duh) and for providing some images used, (to which they retain copyright and are used with permission)
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );

  return (
    <Navbar className="darkmode" variant="dark" collapseOnSelect expand="lg">
      {BasicModal()}
      <Container fluid>
      <Navbar.Brand href="/">
            <img
              alt=""
              src="/logo30.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
            {" "}
            For Honor Infohub<div style={{fontSize:"0.85rem", display:"inline"}}>{" "}{characterName && `(${characterName})`}</div></Navbar.Brand>
            <Navbar.Toggle aria-controls="offcanvasNavbar" />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Navbar.Brand>For Honor Infohub</Navbar.Brand>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="me-auto" activeKey={window.location.pathname}>
              <Nav.Item>
                <Nav.Link href="/">Characters</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/generalinfo">General Information</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/featsandperks">Feats and Perks</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/resources">Community Resources</Nav.Link>
              </Nav.Item>
              <NavDropdown title="Reference Tables" id="basic-nav-dropdown">
                <NavDropdown.Item href="/characterstats">
                  Character Stats
                </NavDropdown.Item>
                <NavDropdown.Item href="/attacks">
                  All Attacks
                </NavDropdown.Item>
                <NavDropdown.Item href="/executions">
                  Executions
                </NavDropdown.Item>
                <NavDropdown.Item href="/punishes">
                  All Punishes
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Item>
                <Nav.Link href="https://discord.gg/Ej7Wk4mMxh" target="_blank" rel="noreferrer">Discord Server</Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={handleOpen}>
                <Nav.Link>
                  <QuestionMarkIcon
                    className="QuestionMarkNavLink"
                    fontSize="small"
                    sx={{ marginBottom: "0.2rem" }}
                  />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
