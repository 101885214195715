import React from "react";
import "./Perk.css";

import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ChatIcon from "@mui/icons-material/ChatSharp";

const headerStyle = {
  marginTop: "5px",
  marginBottom: "10px",
  color: "rgb(58,58,58)",
};

const Perk = ({
  name,
  description,
  category,
  perk_slot,
  base_perk_slot,
  explanation,
  image,
}) => {
  return (
    <div className="perk">
      <div className={"perk-name-and-description"}>
        <div className={"perkHeader" + perk_slot}>
          <div className={"perkHeader" + base_perk_slot}>
          <div className="perk-icon">
            {image && <img src={"perks/"+image+".png"} alt="perkIcon" className='perkIcon' draggable="false"/>}
            </div>
            <div className="perk-name">
              <div>{name}</div>
              <div className="perk-category">
                <div>{category} Perk</div>
              </div>
            </div>
            <hr style={headerStyle} />
          </div>
        </div>
        <div className="perk-description">
        {image && <img src={"perks/"+image+".png"} alt="perkImage" className='perkImage' draggable="false"/>}
          <div className="perk-description-explanation">{description}</div>
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="top"
            overlay={
              <Popover>
                <Popover.Header as="h3">{"Perk Assessment"}</Popover.Header>
                <Popover.Body>
                  {explanation ||
                    "We are still assessing this perk - it's coming Soon™️ Wait.. a new perk..?"}
                </Popover.Body>
              </Popover>
            }
          >
            <center>
              <ChatIcon fontSize="large" sx={{ marginBottom: "0.75rem" }} />
            </center>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};

export default Perk;
