import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

import "./../App.css";

const CharacterStatsTable = ({ characterId }) => {
  const [data, setData] = React.useState([]);
  const columns = [
    {
      dataField: "name",
      text: "Character",
      sort: true,
      headerAlign: "center",
      filter: textFilter({placeholder: "Search..."}),
      style: {
        textAlign: "center",
        width: "7em",
      },
    },
    {
      dataField: "class",
      text: "Class",
      filter: textFilter({placeholder: "Search..."}),
      sort: true,
      headerAlign: "center",
      style: {
        textAlign: "center",
        width: "5.5em",
      },
    },
    {
      dataField: "faction",
      text: "Faction",
      filter: textFilter({placeholder: "Search..."}),
      sort: true,
      headerAlign: "center",
      style: {
        textAlign: "center",
        width: "6em",
      },
    },
    {
      dataField: "health",
      text: "Health",
      sort: true,
      headerAlign: "center",
      filter: textFilter({placeholder: "Search..."}),
      style: {
        textAlign: "center",
        width: "6em",
      },
    },
    {
      dataField: "stamina",
      text: "Stamina",
      sort: true,
      headerAlign: "center",
      filter: textFilter({placeholder: "Search..."}),
      style: {
        textAlign: "center",
        width: "6.5em",
      },
    },
    {
      dataField: "default_guard_dir",
      text: "Default Guard Direction",
      sort: true,
      headerAlign: "center",
      filter: textFilter({placeholder: "Search..."}),
      style: {
        textAlign: "center",
        width: "5em",
      },
    },
    {
      dataField: "off_target_enhanced",
      text: "Off Target Enhanced Attacks?",
      sort: true,
      headerAlign: "center",
      filter: textFilter({placeholder: "Search..."}),
      style: {
        textAlign: "center",
        width: "16em",
      },
    },
    {
      dataField: "sprint_speed",
      text: "Sprint Speed (m/s)",
      sort: true,
      headerAlign: "center",
      filter: textFilter({placeholder: "Search..."}),
      style: {
        textAlign: "center",
        width: "7em",
      },
    },
    {
      dataField: "super_sprint_speed",
      text: "Super Sprint Speed (m/s)",
      sort: true,
      headerAlign: "center",
      filter: textFilter({placeholder: "Search..."}),
      style: {
        textAlign: "center",
        width: "7em",
      },
    },
    {
      dataField: "walkspeed_forward",
      text: "Walkspeed (forward) (m/s)",
      sort: true,
      headerAlign: "center",
      filter: textFilter({placeholder: "Search..."}),
      style: {
        textAlign: "center",
        width: "6em",
      },
    },
    {
      dataField: "walkspeed_sides",
      text: "Walkspeed (sides) (m/s)",
      sort: true,
      headerAlign: "center",
      filter: textFilter({placeholder: "Search..."}),
      style: {
        textAlign: "center",
        width: "6em",
      },
    },
    {
      dataField: "walkspeed_back",
      text: "Walkspeed (back) (m/s)",
      sort: true,
      headerAlign: "center",
      filter: textFilter({placeholder: "Search..."}),
      style: {
        textAlign: "center",
        width: "6em",
      },
    },
    {
      dataField: "fwd_dodge_recovery",
      text: "Forward Dodge Recovery (ms)",
      sort: true,
      headerAlign: "center",
      filter: textFilter({placeholder: "Search..."}),
      style: {
        textAlign: "center",
        width: "6em",
      },
    },
  ];
  const getData = async () => {
    const url = "https://forhonorinfohub.com/api/CharacterStats";
    const { data } = await axios.get(url);
    setData(data);
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <BootstrapTable
        keyField="ID"
        data={data}
        columns={columns}
        striped
        filter={filterFactory()}
        filterPosition="top"
        noDataIndication="There is no data that matches those filters."
      />
    </div>
  );
};

export default CharacterStatsTable;
