import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import React from "react";

import "./CharacterStance.css";
import Container from '@mui/material/Container';

const CharacterStance = ({ CharacterStance, faction} ) => {
  const [open, setOpen] = React.useState({
    speed: true,
    damage: true,
    stamina: true,
    notes: true,
  });

  const [collapsible, setCollapsible] = React.useState(true);
  const handleClick = (name) => {
    const updatedValue = { [name]: !open[name] };

    setOpen((open) => ({
      ...open,
      ...updatedValue,
    }));
  };
  if (!CharacterStance) {
    return;
  }
  return (
    <div>
      <div id="Stance"/>
      <header className={faction}>
          <h1 className="text-center">Character Stance</h1>
          <div
            onClick={() => {
              setCollapsible(!collapsible);
            }}
            style={{ cursor: "pointer" }}
          >
            {collapsible ? <ExpandLess sx={{ }} /> : <ExpandMore sx={{ }} />}
          </div>
        </header>
      
      {collapsible && (
      <Container maxWidth="sm">
      <List
        sx={{
          margin:"2%",
          bgcolor: "background.paper",
          boxShadow: "3px 3px 3px 3px grey",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader disableSticky="true" component="div" id="nested-list-subheader">
            {CharacterStance.name}
          </ListSubheader>
        }
      >
        {CharacterStance.input_method && (
        <ListItemButton>
          <ListItemText primary="Input Method" />
          {CharacterStance.input_method || "N/A"}
        </ListItemButton>
        )}

        {CharacterStance.entry_speed && (
        <ListItemButton className="blueField">
          <ListItemText primary="Entry Speed (ms)" />
          {CharacterStance.entry_speed || "N/A"}
        </ListItemButton>
        )}

        {CharacterStance.recovery_cancel_entry_speed && (
        <ListItemButton className="blueField">
          <ListItemText primary="Recovery Cancel Entry Speed (ms)" />
          {CharacterStance.recovery_cancel_entry_speed || "N/A"}
        </ListItemButton>
        )}

        {CharacterStance.exit_speed && (
        <ListItemButton className="redField">
          <ListItemText primary="Exit Speed (ms)" />
          {CharacterStance.exit_speed || "N/A"}
        </ListItemButton>
        )}

        {CharacterStance.entry_stamina_usage && (
        <ListItemButton className="greenField">
          <ListItemText primary="Entry Stamina Usage" />
          {CharacterStance.entry_stamina_usage || "N/A"}
        </ListItemButton>
        )}

        {CharacterStance.passive_stamina_drain && (
        <ListItemButton>
          <ListItemText primary="Passive Stamina Drain (per second)" />
          {CharacterStance.passive_stamina_drain || "N/A"}
        </ListItemButton>
        )}

        {CharacterStance.stance_duration && (
        <ListItemButton>
          <ListItemText primary="Stance Duration (ms)" />
          {CharacterStance.stance_duration || "N/A"}
        </ListItemButton>
        )}

        {CharacterStance.iframe_window && (
        <ListItemButton>
          <ListItemText primary="I-Frame Quantity (ms)" />
          {CharacterStance.iframe_window || "N/A"}
        </ListItemButton>
        )}

      {CharacterStance.low_hitstun_countered_drain && (
        <ListItemButton>
          <ListItemText primary="Low Hitstun Countered Stamina Drain" />
          {CharacterStance.low_hitstun_countered_drain || "N/A"}
        </ListItemButton>
        )}

      {CharacterStance.med_hitstun_countered_drain && (
        <ListItemButton>
          <ListItemText primary="Medium Hitstun Countered Stamina Drain" />
          {CharacterStance.med_hitstun_countered_drain || "N/A"}
        </ListItemButton>
        )}

      {CharacterStance.high_hitstun_countered_drain && (
        <ListItemButton>
          <ListItemText primary="High Hitstun Countered Stamina Drain" />
          {CharacterStance.high_hitstun_countered_drain || "N/A"}
        </ListItemButton>
        )}

      {CharacterStance.low_hitstun_countered_drain_external && (
        <ListItemButton>
          <ListItemText primary="Low Hitstun Countered Stamina Drain (external)" />
          {CharacterStance.low_hitstun_countered_drain_external || "N/A"}
        </ListItemButton>
        )}

      {CharacterStance.med_hitstun_countered_drain_external && (
        <ListItemButton>
          <ListItemText primary="Medium Hitstun Countered Stamina Drain (external)" />
          {CharacterStance.med_hitstun_countered_drain_external || "N/A"}
        </ListItemButton>
        )}

      {CharacterStance.high_hitstun_countered_drain_external && (
        <ListItemButton>
          <ListItemText primary="High Hitstun Countered Stamina Drain (external)" />
          {CharacterStance.high_hitstun_countered_drain_external || "N/A"}
        </ListItemButton>
        )}

        {CharacterStance.notes && (
        <ListItemButton className="ListItemNotes"
          sx={{ backgroundColor: "lightgrey" }}
          onClick={() => {
            handleClick("notes");
          }}
        >
          <ListItemText primary="Notes" />
          {open["notes"] ? <ExpandLess  sx={{ }} /> : <ExpandMore sx={{ }} />}
        </ListItemButton>
        )}
        {CharacterStance.notes && (
        <Collapse in={open["notes"]} timeout="auto" unmountOnExit>
          <ListItemButton sx={{ }}>
            {CharacterStance.notes || "N/A"}
          </ListItemButton>
        </Collapse>
        )}
      </List>
      </Container>
      )}
    </div>
  );
};

export default CharacterStance;
