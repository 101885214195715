import React from "react";
import "./CharacterMoveset.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const CharacterMoveset = ({ characterInfo }) => {
  if (
    !characterInfo ||
    characterInfo.length < 1 ||
    characterInfo === undefined
  ) {
    return;
  }
  const rows = {
    type: characterInfo.class,
    health: characterInfo.health,
    stamina: characterInfo.stamina,
    guard_type: characterInfo.guard_type,
    off_target_enhanced: characterInfo.off_target_enhanced,
    default_guard_dir: characterInfo.default_guard_dir,
    sprint_speed: characterInfo.sprint_speed,
    walkspeed_forward: characterInfo.walkspeed_forward,
    walkspeed_sides: characterInfo.walkspeed_sides,
    walkspeed_back: characterInfo.walkspeed_back,
    fwd_dodge_recovery: characterInfo.fwd_dodge_recovery,
    super_sprint_speed: characterInfo.super_sprint_speed,
    walkspeed_stance_forward: characterInfo.walkspeed_stance_forward,
    walkspeed_stance_sides: characterInfo.walkspeed_stance_sides,
    walkspeed_stance_back: characterInfo.walkspeed_stance_back,

  };

  return (
    <div>
      <TableContainer>
        <Table
          sx={{
            minWidth: 650,
            maxWidth: 1000,
            display: "table",
            boxShadow: 3,
            marginBottom: "10px",
            color: "white",
            backgroundColor: "#333333"
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Health</TableCell>
              <TableCell>Stamina</TableCell>
              <TableCell>Dodge Guard Type</TableCell>
              <TableCell>Default Guard Direction</TableCell>
              <TableCell>Off-Target Enhanced Attacks</TableCell>
              <TableCell>Sprint Speed (m/s)</TableCell>
              {characterInfo?.super_sprint_speed && (
                <TableCell> Super Sprint Speed (m/s)</TableCell>
              )}
              <TableCell>
                <div>
                  <div style={{ marginBottom: "10px" }}>
                    {" "}
                    Locked-on Walk Speed (m/s)
                  </div>
                  <div className="walk-speed-types">
                   
                    <div>Forward</div>
                    <div>Sides</div>
                    <div>Back</div>
                  </div>
                </div>
              </TableCell>
              {characterInfo?.walkspeed_stance_forward && (
                <TableCell>                <div>
                <div style={{ marginBottom: "10px" }}>
                  {" "}
                  Stance Walk Speed (m/s)
                </div>
                <div className="walk-speed-types">
                 
                  <div>Forward</div>
                  <div>Sides</div>
                  <div>Back</div>
                </div>
              </div></TableCell>
              )}
              <TableCell>Forward Dodge Recovery (ms)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={rows.name}
              sx={{ "th": { border: 0,alignSelf:'center' } }}
            >
              <TableCell>{rows.type}</TableCell>
              <TableCell>{rows.health}</TableCell>
              <TableCell>{rows.stamina}</TableCell>
              <TableCell>{rows.guard_type}</TableCell>
              <TableCell>{rows.default_guard_dir}</TableCell>
              <TableCell>{rows.off_target_enhanced}</TableCell>
              <TableCell>{rows.sprint_speed}</TableCell>
              {characterInfo?.super_sprint_speed && (
                <TableCell>{rows.super_sprint_speed}</TableCell>
              )}

              <TableCell>
                <div className="walk-speed-values">
                  
                  <div >
                    {rows.walkspeed_forward}
                  </div>
                  <div  >
                    {rows.walkspeed_sides}
                  </div>
                  <div >{rows.walkspeed_back}</div>
                </div>
              </TableCell>
              {characterInfo?.walkspeed_stance_forward && (
                <TableCell><div className="walk-speed-values">
                  
                <div >
                  {rows.walkspeed_stance_forward}
                </div>
                <div  >
                  {rows.walkspeed_stance_sides}
                </div>
                <div >{rows.walkspeed_stance_back}</div>
              </div></TableCell>
              )}
              <TableCell>{rows.fwd_dodge_recovery}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CharacterMoveset;
