import "./App.css";
import NavigationBar from "./components/NavigationBar";
import Characters from "./pages/Characters";
import Executions from "./pages/Executions";
import FeatsAndPerks from "./pages/FeatsAndPerks";
import Information from "./pages/Information";
import CharacterPage from "./pages/CharacterPage";
import Attacks from "./pages/Attacks";
import Resources from "./pages/Resources";
import Punishes from "./pages/Punishes";
import CharacterStats from "./pages/CharacterStats";
import NotFoundPage from "./pages/NotFound";
import { Switch, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

function ErrorFallback({ error }) {
  return (
    <div role="alert">
      <center>
        <h3>
          There is no hero with this ID, check that you've typed the URL
          correctly. If you got to this page by some other method, then there's
          something wrong - please contact me on twitter, my handle is
          @NutellaFH
        </h3>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </center>
    </div>
  );
}

function App() {
  return (
    <div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <NavigationBar />
        <Switch>
          <Route path="/" exact>
            <Characters />
          </Route>

          <Route path={"/character/"}>
            <CharacterPage />
          </Route>

          <Route path="/executions">
            <Executions />
          </Route>

          <Route path="/featsandperks">
            <FeatsAndPerks />
          </Route>

          <Route path="/resources">
            <Resources />
          </Route>

          <Route path="/attacks">
            <Attacks />
          </Route>

          <Route path="/punishes">
            <Punishes />
          </Route>

          <Route path="/characterstats">
            <CharacterStats />
          </Route>

          <Route path="/generalinfo">
            <Information />
          </Route>
          <Route path="" component={NotFoundPage} />
        </Switch>
      </ErrorBoundary>
    </div>
  );
}

export default App;
