import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { MultiSelect } from "react-multi-select-component";
import "./CharacterExecutionTable.css";

import "./../App.css";

const ExecutionTable = ({ characterId }) => {
  const [data, setData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [hpFilters, setHpFilters] = React.useState([]);
  const [characterFilters, setCharacterFilters] = React.useState([]);
  const [isDropdown, setDropdown] = React.useState(false);
  const inputRef = React.useRef(
    document.querySelector(".react-bootstrap-table")
  );
  let box = document.querySelector(".react-bootstrap-table");
  inputRef.current = box?.clientHeight;
  const hpData = [];
  const charactersData = [];
  const defaultSorted = [{
    dataField: 'CharacterID',
    order: 'asc'
  }];
  
  data?.map((characterName) => {
    !charactersData.includes(characterName?.CharacterName) &&
      charactersData.push(characterName?.CharacterName);
  });
  
  data?.map((characterHp) => {
    !hpData.includes(characterHp?.hp_gain) && hpData.push(characterHp?.hp_gain);
  });
  let selectHpData = [];
  let selectCharacterData = [];
  hpData?.map((characterHp, index) => {
    const temp = { label: characterHp, value: characterHp };
    selectHpData.push(temp);
  });
  charactersData?.map((characterHp, index) => {
    const temp = { label: characterHp, value: characterHp };
    selectCharacterData.push(temp);
  });
  
 
  selectHpData = sortByKey(selectHpData, "label");
  function sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
  React.useEffect(() => {
    const tempFilters = [];
    const tempFilters2 = [];
    data?.map((characterInfo, index) => {
      let isFound = false;

      characterFilters.length &&
        characterFilters?.map((characterFilter) => {
          characterFilter?.value === characterInfo?.CharacterName &&
            tempFilters.push(characterInfo);
        });
    });
    (tempFilters.length ? tempFilters : data)?.map((characterInfo, index) => {
      hpFilters?.length &&
        hpFilters?.map((hpFilter) => {
          hpFilter?.value === characterInfo?.hp_gain &&
            tempFilters2.push(characterInfo);
        });
    });
    setFilteredData(tempFilters2.length ? tempFilters2 : tempFilters);
  }, [characterFilters, hpFilters]);
  
  const hpHeader = (column) => {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", maxWidth: "10rem" }}
      >
        <div
          style={{
            marginBottom: "1rem",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          {column.text}
        </div>
        <div style={{ marginBottom: "-3.5rem", maxWidth: "7rem" }}>
          <MultiSelect
            options={selectHpData}
            value={hpFilters}
            onChange={setHpFilters}
            labelledBy="Select"
            onMenuToggle={() => {
              setDropdown(!isDropdown);
            }}
          />
        </div>
      </div>
    );
  };
  const characterNameHeader = (column) => {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", maxWidth: "10rem" }}
      >
        <div
          style={{
            marginBottom: "1rem",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          {column.text}
        </div>
        <div style={{ marginBottom: "-3.5rem", maxWidth: "9rem" }}>
          <MultiSelect
            options={selectCharacterData}
            value={characterFilters}
            onChange={setCharacterFilters}
            labelledBy="Select"
            onMenuToggle={() => {
              setDropdown(!isDropdown);
            }}
          />
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "ID",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "CharacterID",
      text: "CharacterID",
      hidden: true,
    },
    {
      dataField: "CharacterName",
      text: "Character",
      headerAlign: "center",
      headerFormatter: characterNameHeader,
      hidden: characterId ? true : false,
      style: {
        width: "10em",
        textAlign: "center",
        fontWeight: "bold"
      },
    },
    {
      dataField: "name",
      text: "Execution Name",
      headerAlign: "center",

      filter: textFilter({placeholder: "Search..."}),
      style: {
        width: "12em",
        textAlign: "center",
      },
    },
    {
      dataField: "kill_time",
      text: "Kill Time (ms)",
      headerAlign: "center",
      sort: true,

      style: {
        width: "9em",
        textAlign: "center",
      },
    },
    {
      dataField: "user_duration",
      text: "User Duration (ms)",
      headerAlign: "center",
      sort: true,

      style: {
        width: "11.5em",
        textAlign: "center",
      },
    },
    {
      dataField: "victim_duration",
      text: "Victim Duration (ms)",
      headerAlign: "center",
      sort: true,

      style: {
        width: "12.5em",
        textAlign: "center",
      },
    },
    {
      dataField: "hp_gain",
      text: "HP Gain",
      headerAlign: "center",
      headerFormatter: hpHeader,

      style: {
        width: "8em",
        textAlign: "center",
      },
    },
    // {
    //   dataField: "in_lock",
    //   text: "In Lock?",
    //   headerAlign: "center",
    //   sort: true,

    //   style: {
    //     width: "6.3em",
    //     textAlign: "center",
    //   },
    // },
    {
      dataField: "can_ledge",
      text: "Ragdolls?",
      headerAlign: "center",
      sort: true,

      style: {
        width: "7em",
        textAlign: "center",
      },
    },
    {
      dataField: "notes",
      text: "Notes",
      headerAlign: "center",
      sort: true,

      style: {
        width: "fit-content",
        textAlign: "center",
      },
    },
  ];
  const getData = async () => {
    const url = characterId
      ? `https://forhonorinfohub.com/api/CharacterUniqueExecutions/Id?characterId=${characterId}`
      : "https://forhonorinfohub.com/api/CharacterUniqueExecutions";
    const { data } = await axios.get(url);
    setData(data);
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <div className="executionsTable">
      <div className="table-responsive">
        <BootstrapTable
          keyField="ID"
          filterPosition="top"
          data={Boolean(filteredData.length) ? filteredData : data}
          columns={columns}
          striped
          filter={filterFactory()}
          defaultSorted = {defaultSorted}
          noDataIndication="There is no data that matches those filters."
          id={`${
            Boolean(filteredData?.length) &&
            filteredData?.length < 8 &&
            isDropdown &&
            "lessFilteredItems"
          }`}
        />
       
        
      </div>
    </div>
  );
};

export default ExecutionTable;
