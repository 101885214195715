import Feat from "../components/Feat";
import Perk from "../components/Perk";
import "./FeatsAndPerks.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import MenuIcon from "@mui/icons-material/Menu";
import Title from "./../components/Title";
import Table from "react-bootstrap/Table";
import CircularProgress from "@mui/material/CircularProgress";

const styledInfo = {
  paddingLeft: "15%",
  paddingRight: "15%",
};

const Feats = () => {
  const [featsData, setFeatsData] = React.useState({});
  const [perksData, setPerksData] = React.useState({});
  React.useEffect(() => {
    getFeats();
    getPerks();
  }, []);

  const getFeats = () => {
    fetch("https://forhonorinfohub.com/api/Feats")
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        let grouped = Object.create(null);
        myJson.forEach(function (a) {
          grouped[a.tier] = grouped[a.tier] || [];
          grouped[a.tier].push(a);
        });

        setFeatsData(grouped);
      });
  };

  const getPerks = () => {
    fetch("https://forhonorinfohub.com/api/Perks")
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        let grouped = Object.create(null);
        myJson.forEach(function (b) {
          grouped[b.category] = grouped[b.category] || [];
          grouped[b.category].push(b);
        });

        setPerksData(grouped);
      });
  };

  const fabStyle = {
    position: "fixed",
    bottom: "3%",
    left: "2%",
  };

  const styledLink = {
    textDecoration: "none",
    color: "var(--bs-light)",
  };

  function NavPane({ name, ...props }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);

    return (
      <>
        <Fab style={fabStyle} onClick={toggleShow}>
          <MenuIcon />
        </Fab>
        <Offcanvas
          show={show}
          scroll={true}
          restoreFocus={false}
          onHide={handleClose}
          {...props}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Jump to...</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <a style={styledLink} onClick={toggleShow} href="#tier1">
              Tier 1 Feats
            </a>
            <br />
            <br />
            <a style={styledLink} onClick={toggleShow} href="#tier2">
              Tier 2 Feats
            </a>
            <br />
            <br />
            <a style={styledLink} onClick={toggleShow} href="#tier3">
              Tier 3 Feats
            </a>
            <br />
            <br />
            <a style={styledLink} onClick={toggleShow} href="#tier4">
              Tier 4 Feats
            </a>
            <br />
            <br />
            <a style={styledLink} onClick={toggleShow} href="#PerkInfo">
              Perk Information
            </a>
            <br />
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Offense">
              Offensive Perks
            </a>
            <br />
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Defense">
              Defensive Perks
            </a>
            <br />
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Assist">
              Assist Perks
            </a>
            <br />
            <br />
            <br />
            <br />
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }

  return (
    <div id="top">
      <NavPane placement={"start"} />
      {console.log(
        Boolean(Object.keys(featsData).length) &&
          Boolean(Object.keys(perksData).length)
      )}
      {Boolean(Object.keys(featsData).length) &&
      Boolean(Object.keys(perksData).length) ? (
        Object.keys(featsData).map((key, index) => (
          <div className="tier-and-feats" key={index}>
            <div id={"tier" + key} className={"tier" + key}>
              Tier {key}
            </div>
            <div className="feats">
              {featsData[key]?.map((item, key) => (
                <Feat
                  key={key}
                  name={item.name}
                  tier={"★".repeat(item.tier)}
                  activation_time={item.activation_time}
                  cooldown={item.cooldown}
                  description={item.description}
                  recovery_time={item.recovery_time}
                  explanation={item.explanation}
                  image={item?.image}
                />
              ))}
            </div>
          </div>
        ))
      ) : (
        <CircularProgress className="circular-progress"/>
      )}
 { (Boolean(Object.keys(featsData).length) &&
      Boolean(Object.keys(perksData).length)) ?
      <>
      <div id="PerkInfo" />
      <Title text="Perks" />
      <div style={styledInfo} className="mt-2 mb-2">
        <center>
          <p className="lead">
            See the figure below for all possible perk combinations.
          </p>
        </center>
      </div>
      <div className="tableBox">
        <Table
          className="perksTable"
          bordered
          responsive="sm"
          style={{ borderColor: {value: "#252526", important: 'true'}, backgroundColor: {value: "#252526", important: 'true'}}}
        >
          <tbody>
            <tr>
              <td></td>
              <td style={{ backgroundColor: "rgb(120,120,120)" }}>Perk A</td>
              <td style={{ backgroundColor: "rgb(120,120,120)" }}>Perk B</td>
              <td style={{ backgroundColor: "rgb(120,120,120)" }}>Perk C</td>
              <td style={{ backgroundColor: "#005DA0" }}>Perk D</td>
              <td style={{ backgroundColor: "#68348A" }}>Perk E</td>
              <td style={{ backgroundColor: "#C29124" }}>Perk F</td>
              <td style={{ backgroundColor: "#15897d" }}>Perk G</td>
            </tr>
            <tr>
              <th
                style={{
                  backgroundColor: "rgb(120,120,120)",
                  verticalAlign: "middle",
                }}
              >
                Common (Rep 0/1)
              </th>
              <td style={{ backgroundColor: "rgb(150, 150, 150)" }}>Pick 1</td>
              <td style={{ backgroundColor: "rgb(150, 150, 150)" }} colSpan={2}>
                Pick 1
              </td>
              <td colSpan={4}></td>
            </tr>
            <tr>
              <th
                style={{ backgroundColor: "#005DA0", verticalAlign: "middle" }}
              >
                Rare (Rep 2/3)
              </th>
              <td style={{ backgroundColor: "rgb(150, 150, 150)" }} colSpan={4}>
                Pick 2
              </td>
              <td colSpan={3}></td>
            </tr>
            <tr>
              <th
                style={{ backgroundColor: "#68348A", verticalAlign: "middle" }}
                rowSpan={2}
              >
                Heroic (Rep 4/5)
              </th>
              <td style={{ backgroundColor: "rgb(150, 150, 150)" }} colSpan={4}>
                Pick 2
              </td>
              <td colSpan={3}></td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "rgb(150, 150, 150)" }} colSpan={3}>
                Pick 1
              </td>
              <td></td>
              <td style={{ backgroundColor: "rgb(150, 150, 150)" }}>Pick 1</td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <th
                style={{ backgroundColor: "#C29124", verticalAlign: "middle" }}
                rowSpan={3}
              >
                Epic (Rep 6/7)
              </th>
              <td style={{ backgroundColor: "rgb(150, 150, 150)" }} colSpan={3}>
                Pick 3
              </td>
              <td colSpan={4}></td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "rgb(150, 150, 150)" }} colSpan={4}>
                Pick 2
              </td>
              <td colSpan={3}></td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "rgb(150, 150, 150)" }} colSpan={4}>
                Pick 1
              </td>
              <td style={{ backgroundColor: "rgb(150, 150, 150)" }} colSpan={2}>
                Pick 1
              </td>
              <td></td>
            </tr>
            <tr>
              <th
                style={{ backgroundColor: "#15897d", verticalAlign: "middle" }}
                rowSpan={3}
              >
                Legendary (Rep 7/8+)
              </th>
              <td style={{ backgroundColor: "rgb(150, 150, 150)" }} colSpan={4}>
                Pick 3
              </td>
              <td colSpan={4}></td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "rgb(150, 150, 150)" }} colSpan={4}>
                Pick 2
              </td>
              <td style={{ backgroundColor: "rgb(150, 150, 150)" }} colSpan={3}>
                Pick 1
              </td>
            </tr>
            <tr>
              <td colSpan={4}></td>
              <td style={{ backgroundColor: "rgb(150, 150, 150)" }} colSpan={3}>
                Pick 2
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      {Object.keys(perksData).map((key, index) => (
        <div className="tier-and-feats" key={index}>
          <div id={key} className={key}>
            {key} Perks
          </div>
          <div className="perks">
            {perksData[key]?.map((item, key) => (
              <Perk
                key={key}
                name={item.name}
                category={item.category}
                description={item.description}
                explanation={item.explanation}
                base_perk_slot={item.base_perk_slot}
                image={item?.image}
              />
            ))}
          </div>
        </div>
      ))}
      </>:<CircularProgress className="circular-progress" />}
      
    </div>
  );
};

export default Feats;
