import React from "react";
import PunishTable from "./../components/PunishTable.js";

const styledInfo = {
  paddingLeft: "20%",
  paddingRight: "20%",
};

const Punishes = () => {
  return (
    <>
      <div id="top" style={styledInfo} className="my-5">
        <center>
          <p className="lead">
            Below is a table of all punishes. You can filter and sort by columns.
          </p>
        </center>
      </div>
      <div id="PunishesTable" className="table-responsive">
        <PunishTable />
      </div>
    </>
  );
};

export default Punishes;
