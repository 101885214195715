import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import { MultiSelect } from "react-multi-select-component";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import "./CharacterPunishTable.css";

import "./../App.css";

const defaultSorted = [{
  dataField: 'punish_order',
  order: 'asc'
}];

const CharacterPunishTable = ({ characterId, faction }) => {
  const [data, setData] = React.useState([]);
  const [filteredData, setFilteredData]=React.useState([])
  const [punishFilters, setPunishFilters] = React.useState([]);
  const [isDropdown,setDropdown]=React.useState(false)
  const inputRef = React.useRef(document.querySelector('.react-bootstrap-table'))
  let box = document.querySelector('.react-bootstrap-table');
  inputRef.current=box?.clientHeight
  
  const punishData = [("Light Parry"), ("Heavy Parry"), ("Guard Break"), ("Wall Throw"), ("OOS Parry"), ("OOS Throw"), ("Revenge Auto Parry"), ("Opponent Out-of-Lock"), ("OOS + Out-of-Lock"), ("Deflect/Superior Block Dodge"), ("Other Punishes")];
  data?.map((characterPunish) => {
    !punishData.includes(characterPunish?.type_of_punish) && punishData.push(characterPunish?.type_of_punish);
  });
  let selectPunishData = [];
  punishData?.map((characterPunish, index) => {
    const temp = { label: characterPunish, value: characterPunish };
    selectPunishData.push(temp);
  });
  React.useEffect(()=>{
    const tempFilters=[]
    punishFilters?.map((filter)=>{
      data?.map((characterPunishes)=>filter?.value === characterPunishes?.type_of_punish && tempFilters.push(characterPunishes))

    })
    setFilteredData(tempFilters);
  },[punishFilters])
  const punishHeader = (column) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" ,maxWidth:'12rem'}}>
        <div style={{marginBottom:'1rem',display:"flex",alignItems:'flex-end',justifyContent:'center'}}>
        {column.text}
          </div>
        <div style={{marginBottom:'-3.5rem', maxWidth: "12rem"}}>
          <MultiSelect
            options={selectPunishData}
            value={punishFilters}
            onChange={setPunishFilters}
            labelledBy="Select"
            onMenuToggle={()=>{setDropdown(!isDropdown)}}
          />
        </div>
      </div>
    );
  };
  const columns = [
    {
      dataField: "Character",
      text: "Character",
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
      hidden: characterId ? true : false,
      style: {
        width: "8em",
      },
    },
    {
      dataField: "punish_order",
      text: "punish_order",
      hidden: true,
      sort:true
    },
    {
      dataField: "type_of_punish",
      text: "Type of Punish",
      headerAlign: "center",
      headerFormatter: punishHeader,
      style: {
        textAlign: "center",
        width: "12em",
        fontWeight: "bold"
      },
    },
    {
      dataField: "instructions",
      headerAlign: "center",
      text: "Instructions",
      style: {
        textAlign: "center",
      },
    },
    {
      dataField: "damage",
      text: "Damage",
      headerAlign: "center",
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
      style: {
        textAlign: "center",
        width: "8em",
      },
    },
    {
      dataField: "StaminaCost",
      text: "Stamina Cost",
      headerAlign: "center",
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
      style: {
        textAlign: "center",
      },
    },	
    {
      dataField: "notes",
      headerAlign: "center",
      text: "Notes",
      filter: textFilter({placeholder: "Search..."}),
      style: {
        textAlign: "center",
        width: "30em",
      },
    },
  ];

  const getData = async () => {
    const url = characterId
      ? `https://forhonorinfohub.com/api/CharacterMaxPunishes?characterId=${characterId}`
      : "https://forhonorinfohub.com/api/CharacterMaxPunishes";
    const { data } = await axios.get(url);
    setData(data);
  };
  React.useEffect(() => {
    getData();
  }, []);
  const [collapsible, setCollapsible] = React.useState(true);
  return (
    <div>
      <div>
        <header className={faction}>
          <h1 className="text-center">Max Punishes</h1>
          <div
            onClick={() => {
              setCollapsible(!collapsible);
            }}
            style={{ cursor: "pointer" }}
          >
            {collapsible ? <ExpandLess sx={{ }} /> : <ExpandMore sx={{ }} />}
          </div>
        </header>
      </div>
      {collapsible && (
        <div className="punishesTable">
          <div className="table-responsive">
          <BootstrapTable
            keyField="ID"
            data={Boolean(filteredData.length) ? filteredData : data}
            columns={columns}
            striped
            defaultSorted = {defaultSorted}
            filter={filterFactory()}
            filterPosition="top"
            noDataIndication="There is no data that matches those filters."
            id={`${
              (Boolean(filteredData?.length) && filteredData?.length <8 && isDropdown) &&
              "lessFilteredItems"
            }`}
          />
          </div>
        </div>
      )}
    </div>
  );
};

export default CharacterPunishTable;