import "./NotFound.css";

const NotFound = () => {
  return (
    <div>
      <h2>Error 404, the requested page was not found!</h2>
      <img className="errorImage" src="./error.png" alt="A distorted Nuxia."></img>
    </div>
  );
};

export default NotFound;
