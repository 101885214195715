import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

import "./../App.css";

const ResourceTable = ({ characterId }) => {
  const [data, setData] = React.useState([]);
  const columns = [
    {
      dataField: "link",
      text: "Link",
      style: {
        width: "1em",
      },
      formatter: (cell, row, rowIndex) => (
        <div>
          <a className="styledLinks" href={row.link} target="_blank" rel="noreferrer">
            {" "}
            Click me!
          </a>
        </div>
      ),
    },
    {
      dataField: "Character",
      text: "Character",
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
      hidden: characterId ? true : false,
      style: {
        width: "8em",
      },
    },
    {
      dataField: "description",
      text: "Description",
      filter: textFilter({placeholder: "Search..."}),
      style: {
        width: "16em",
      },
    },
    {
      dataField: "Tags",
      text: "Tags",
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
      style: {
        width: "8em",
      },
    },
    {
      dataField: "author",
      text: "Author",
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
      style: {
        width: "7em",
      },
    },
    {
    dataField: "date",
    text: "Date",
    sort: true,
    sortFunc: (a, b, order) => { 
      if (order === 'asc')
      {
        return Date.parse(a) - Date.parse(b)
      }
     else if (order === 'desc') {
        return  Date.parse(b) - Date.parse(a) 
      }
    },
    filter: textFilter({placeholder: "Search..."}),
    style: {
      width: "4em",
    },
  },
  {
    dataField: "notes",
    text: "Notes",
    style: {
      width: "12em",
    },
  },
  ];
  const getData = async () => {
    const url = "https://forhonorinfohub.com/api/Resources";
    const { data } = await axios.get(url);
    setData(data);
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <BootstrapTable
        keyField="ID"
        data={data}
        columns={columns}
        striped
        filter={filterFactory()}
        filterPosition="top"
        noDataIndication="There is no data that matches those filters."
      />
    </div>
  );
};

export default ResourceTable;
