import React from "react";
import CharacterStatsTable from "./../components/CharacterStatsTable.js";

const styledInfo = {
  paddingLeft: "20%",
  paddingRight: "20%",
};

const tablePadding = {
  paddingLeft: "9%",
  paddingRight: "9%",
};

const CharacterStats = () => {
  return (
    <>
      <div id="top" style={styledInfo} className="my-5">
        <center>
          <p className="lead">
            Below is a table of all character's stats. You can filter and sort
            by columns.
          </p>
        </center>
      </div>
      <div id="StatsTable" className="table-responsive" style={tablePadding}>
        <CharacterStatsTable />
      </div>
    </>
  );
};

export default CharacterStats;
