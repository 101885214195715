import ExecutionTable from "./../components/ExecutionTable.js";
import SharedExecutionTable from "./../components/SharedExecutionTable.js";
import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import MenuIcon from "@mui/icons-material/Menu";
import Title from "./../components/Title";

const styledInfo = {
  paddingLeft: "20%",
  paddingRight: "20%",
};

const tablePadding = {
  paddingLeft: "9%",
  paddingRight: "9%",
};

const styledLink = {
  textDecoration: "none",
  color: "var(--bs-light)",
};

const fabStyle = {
  position: "fixed",
  bottom: "3%",
  left: "2%",
};

function NavPane({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  return (
    <>
      <Fab style={fabStyle} onClick={toggleShow}>
        <MenuIcon />
      </Fab>
      <Offcanvas
        show={show}
        scroll={true}
        restoreFocus={false}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Jump to...</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <a style={styledLink} onClick={toggleShow} href="#UniqueExecutions">
            Unique Executions Table
          </a>
          <br />
          <br />
          <a style={styledLink} onClick={toggleShow} href="#SharedExecutions">
            Shared Executions Info
          </a>
          <br />
          <br />
          <a
            style={styledLink}
            onClick={toggleShow}
            href="#SharedExecutionsTable"
          >
            Shared Executions Table
          </a>
          <br />
          <br />
          <br />
          <br />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

const Executions = () => {
  return (
    <>
      <div id="top" style={styledInfo} className="my-5">
        <NavPane placement={"start"} />
        <center>
          <p className="lead">
            Executions can be performed when killing an opponent with a heavy
            attack, and restore all your stamina, and some health, when they
            reach the kill-point of the execution animation. An executed
            opponent cannot be revived, and must also wait until the end of the
            execution animation before their respawn timer begins.
            <br />
            <br />
            However, unless you are in revenge, an execution can be interrupted
            by another opponent, or enemy soldier or pikeman and if this occurs
            before the kill time, you will not be healed, and the killed
            opponent can be revived by one of their allies. Moreover, being
            interrupted from some executions can leave you vulnerable to an Out
            of Lock punish, for potentially huge damage. This is represented by
            the <b>"In Lock?"</b> property.
            <br />
            <br />
            The <b>"Ragdolls?"</b> property means that the execution converts
            the enemy model into a physics object, meaning that an enemy's body
            can be ledged or dynamically displaced if their body hits an
            obstacle, which will cause a physics based animation of them
            colliding with said object to play, but more importantly,{" "}
            <b>it will stop the victim duration entirely. </b>
            This can cause you to be stuck in an execution for longer than your
            enemy that you've executed, so be wary of this!
            <br />
            <br />
            These properties have significant gameplay consequences, and chosing
            the right execution for the situation can swing a match one way or
            another. As such it is recommended to have several executions to
            choose from. For example, a quick one when you might get
            interrupted, and a longer one to get the most healing when you won't
            get interrupted. Additionally, the psychological effect of
            performing a flashy or obnoxious execution on an opponent should not
            be underestimated for influencing how they might play in the rest of
            the match.
            <br />
            <br />
            We also add an additional 100ms to the User Duration, which is why
            executions with 'correct' victim duration have -100ms compared to
            the user duration. This additional 100ms is measured for the
            purposes of guard swapping/attacking, as your guard comes up where
            it previously was, but you are unable to actually play, so our user
            duration stat is how long until you regain control of your
            character.
            <br />
            <br />
            Executions that are 4100ms or shorter heal for 20 HP
            <br />
            Executions that are in the range of 4200ms to 5100ms heal for 35 HP
            <br />
            Executions that are 5200ms or longer heal for 50 HP
            <br />
            <br />
            The tables below represents all executions in the game, the{" "}
            <b>first</b> is for character specific executions, and the{" "}
            <b>second</b> one is for shared executions. You can also sort
            columns by alphabetical/numerical order via a press of a column
            header.
          </p>
        </center>
      </div>
      <div id="UniqueExecutions">
        <ExecutionTable />
      </div>
      <div id="SharedExecutions" />
      <Title text="Shared Executions" />
      <div style={styledInfo} className="mt-2 mb-2">
        <center>
          <p className="lead">
            Shared executions are executions which are available to the many
            members of the cast, with some of these being time limited, as
            denoted by the <b>availability</b> property. These executions are
            typically longer than unique executions, thanks to the additional
            1000ms of weapon holstering and unholstering times.
            <br />
            <br />
            There's also an extra 200ms timing to the execution before you can
            block in a new direction, with 0ms leaving your guard as empty,
            100ms leaving your guard at it's previous location, and 200ms
            allowing you to able to block a different direction. This means that
            in total, these executions are 1.2s longer than they 'should' be,
            and this time does <b>not</b> count towards the HP gain, meaning
            that you can have an execution that lasts for 6000ms that only heals
            for 35 hp, rather than 50.
            <br />
            <br />
            Executions that are 5200ms or shorter heal for 20 HP
            <br />
            Executions that are in the range of 5300ms to 6200ms heal for 35 HP
            <br />
            Executions that are 6300ms or longer heal for 50 HP
          </p>
        </center>
      </div>
      <div id="SharedExecutionsTable">
        <SharedExecutionTable />
      </div>
    </>
  );
};

export default Executions;
