const Title = ({ text, backdrop, color }) => {
  return (
    <header style={{backgroundColor: "#b84d00", textShadow: "2px 2px rgb(49,49,49)"}}className="text-white p-1 Title-container">
      <h1 className="text-center">{text}</h1>
      {backdrop && (
        <div
          className="Title-backdrop"
          style={{ backgroundImage: `url(${backdrop})` }}
        ></div>
      )}
    </header>
  );
};

export default Title;
