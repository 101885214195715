import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React from "react";
import Fab from "@mui/material/Fab";
import FilterListIcon from '@mui/icons-material/FilterList';
import Table from "react-bootstrap/Table";
import Bulwark from "../img/propertyIcons/bulwark.png";
import FullBlock from "../img/propertyIcons/fullBlock.png";
import Pins from "../img/propertyIcons/pins.png";
import Knockdown from "../img/propertyIcons/knockdown.png";
import Stun from "../img/propertyIcons/stun.png";
import SupBlock from "../img/propertyIcons/supBlock.png";
import Trap from "../img/propertyIcons/trap.png";
import Unblockable from "../img/propertyIcons/unblockable.png";
import Undodgeable from "../img/propertyIcons/undodgeable.png";
import Uninterruptable from "../img/propertyIcons/uninterruptible.png";
import Wallsplat from "../img/propertyIcons/wallsplat.png";
import Bleed from "../img/propertyIcons/bleed.png";
import Deflect from "../img/propertyIcons/deflect.png";
import Parry from "../img/propertyIcons/parry.png";
import SuperiorBlockDodge from "../img/propertyIcons/dodgecounter.png";
import SoftFeints from "../img/propertyIcons/softFeintable.png";
import CharacterStance from "./CharacterStance";
import Grid from "@mui/material/Grid";

import "./CharacterAttacks.css";

const CharacterAttacks = ({ characterAttacks, faction, characterId }) => {
  const [headerDetails, setHeaderDetails] = React.useState({
    "Running Attacks": true,
    "Confirmed Attacks": true,
    "Zone Attacks": true,
    "Basic Attacks": true,
    Ripostes: true,
    "Forward Dodge Attacks": true,
    "Side Dodge Attacks": true,
    "Chain Bashes": true,
    "Neutral Bashes": true,
    "Special Moves": true,
    "Stance Attacks": true,
  });

  const [characterStance, setCharacterStance] = React.useState();
  const [isFilterOpen, setFilterOpen] = React.useState(false);
  const [open, setOpen] = React.useState({
    speed: true,
    damage: true,
    stamina: true,
    specialproperties: true,
    direction: true,
    input: true,
    hitstun: true,
    recoveries: false,
    notes: false,
  });
  const [isNotesOpened, setNotesOpened] = React.useState("");
  React.useEffect(() => {
    getCharacterStance();
  }, []);

  const getCharacterStance = () => {
    fetch(
      `https://forhonorinfohub.com/api/CharacterStance?characterId=${characterId}`
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        setCharacterStance(myJson[0]);
      });
  };
  const handleClick = (attack_name) => {
    const updatedValue = { [attack_name]: !open[attack_name] };

    setOpen((open) => ({
      ...open,
      ...updatedValue,
    }));
  };
  if (!characterAttacks || characterAttacks.length < 1) {
    return;
  }
  const handleNotes = (index) => {
    let temp = isNotesOpened;

    if (temp !== undefined && temp.includes(index)) {
      temp = temp.replace(index, "-");
    } else {
      temp = temp + index;
    }

    setNotesOpened(temp);
  };

  const handleHeader = (attack_name) => {
    const updatedValue = { [attack_name]: !headerDetails[attack_name] };

    setHeaderDetails((open) => ({
      ...headerDetails,
      ...updatedValue,
    }));
  };

  const fabStyle2 = {
    position: "fixed",
    bottom: "3%",
    right: "2%",
  };

  return (
    <div>
      <Fab style={fabStyle2} onClick={() => {
        setFilterOpen(!isFilterOpen);
      }}>
        <FilterListIcon />
      </Fab>
      {isFilterOpen && (
        <div className="filters">
          <ListItemButton
            sx={{}}
            onClick={() => {
              handleClick("damage");
            }}
          >
            <ListItemText primary="Damage" />
            {open["damage"] ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </ListItemButton>
          <ListItemButton
            sx={{}}
            onClick={() => {
              handleClick("speed");
            }}
          >
            <ListItemText primary="Speed" />
            {open["speed"] ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </ListItemButton>
          <ListItemButton
            sx={{}}
            onClick={() => {
              handleClick("stamina");
            }}
          >
            <ListItemText primary="Stamina" />
            {open["stamina"] ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </ListItemButton>

          <ListItemButton
            sx={{}}
            onClick={() => {
              handleClick("input");
            }}
          >
            <ListItemText primary="Input" />
            {open["input"] ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </ListItemButton>

          <ListItemButton
            sx={{}}
            onClick={() => {
              handleClick("direction");
            }}
          >
            <ListItemText primary="Direction" />
            {open["direction"] ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </ListItemButton>

          <ListItemButton
            sx={{}}
            onClick={() => {
              handleClick("hitstun");
            }}
          >
            <ListItemText primary="Hitstun" />
            {open["hitstun"] ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </ListItemButton>

          <ListItemButton
            sx={{}}
            onClick={() => {
              handleClick("specialproperties");
            }}
          >
            <ListItemText primary="Properties" />
            {open["specialproperties"] ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )}
          </ListItemButton>

          <ListItemButton
            sx={{}}
            onClick={() => {
              handleClick("recoveries");
            }}
          >
            <ListItemText primary="Recoveries" />
            {open["recoveries"] ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )}
          </ListItemButton>

          <ListItemButton
            sx={{}}
            onClick={() => {
              handleClick("misc");
            }}
          >
            <ListItemText primary="Misc." />
            {open["misc"] ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </ListItemButton>
        </div>
      )}

      {Object.keys(characterAttacks).map((key, index1) => (
        <div key={index1}>
          {key === "Stance Attacks" && (
            <CharacterStance
              CharacterStance={characterStance}
              faction={faction}
            />
          )}
          {characterAttacks[key] !== undefined && (
            <div id={key}>
              <header className={faction}>
                <h1 className="text-center">{key}</h1>
                <div
                  onClick={() => {
                    handleHeader(key);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {headerDetails[key] ? <ExpandLess sx={{ }} /> : <ExpandMore sx={{ }} />}
                </div>
              </header>
            </div>
          )}

          <Grid>
            {headerDetails[key] === true && (
              <div className="attacks">
                {characterAttacks[key]?.map((item, index2) => (
                  <div className="attack" key={index2}>
                    <List
                      sx={{
                        minWidth: 300,
                        bgcolor: "background.paper",
                        boxShadow: "3px 3px 3px 3px grey",
                      }}
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                      subheader={
                        <ListSubheader
                          component="div"
                          id="nested-list-subheader"
                          disableSticky="true"
                        >
                          <div className="propertyIcons">
                          {item?.special_properties?.includes(
                              "Bulwark Counter"
                            ) && (
                              <img
                                className="propertyImage"
                                src={Bulwark}
                                alt="property"
                              />
                            )}
                            {item?.special_properties?.includes(
                              "All Guard"
                            ) && (
                              <img
                                className="propertyImage"
                                src={FullBlock}
                                alt="property"
                              />
                            )}
                            {item?.special_properties?.includes(
                              "Pins"
                            ) && (
                              <img
                                className="propertyImage"
                                src={Pins}
                                alt="property"
                              />
                            )}
                          {item?.special_properties?.includes(
                              "Knocksdown"
                            ) && (
                              <img
                                className="propertyImage"
                                src={Knockdown}
                                alt="property"
                              />
                            )}
                          {item?.special_properties?.includes(
                              "Stuns"
                            ) && (
                              <img
                                className="propertyImage"
                                src={Stun}
                                alt="property"
                              />
                            )}
                          {item?.special_properties?.includes(
                              "Superior Block"
                            ) && (
                              <img
                                className="propertyImage"
                                src={SupBlock}
                                alt="property"
                              />
                            )}
                          {item?.special_properties?.includes(
                              "Trap"
                            ) && (
                              <img
                                className="propertyImage"
                                src={Trap}
                                alt="property"
                              />
                            )}
                            {item?.special_properties?.includes(
                              "Unblockable"
                            ) && (
                              <img
                                className="propertyImage"
                                src={Unblockable}
                                alt="property"
                              />
                            )}
                            {item?.special_properties?.includes(
                              "Soft-Feintable"
                            ) && (
                              <img
                                className="propertyImage"
                                src={SoftFeints}
                                alt="property"
                              />
                            )}
                            {item?.special_properties?.includes(
                              "Bash"
                            ) && (
                              <img
                                className="propertyImage"
                                src={Unblockable}
                                alt="property"
                              />
                            )}
                            {item?.special_properties?.includes(
                              "Undodgeable"
                            ) && (
                              <img
                                className="propertyImage"
                                src={Undodgeable}
                                alt="property"
                              />
                            )}
                            {item?.special_properties?.includes(
                              "Hyperarmor"
                            ) && (
                              <img
                                className="propertyImage"
                                src={Uninterruptable}
                                alt="property"
                              />
                            )}
                            {item?.special_properties?.includes(
                              "Wallsplats"
                            ) && (
                              <img
                                className="propertyImage"
                                src={Wallsplat}
                                alt="property"
                              />
                            )}
                            {item?.special_properties?.includes(
                              "Bleeds"
                            ) && (
                              <img
                                className="propertyImage"
                                src={Bleed}
                                alt="property"
                              />
                            )}
                            {item?.input_method?.includes(
                              "Superior Block Dodge"
                            ) && (
                              <img
                                className="propertyImage"
                                src={SuperiorBlockDodge}
                                alt="property"
                              />
                            )}
                            {item?.input_method?.includes(
                              "Parry"
                            ) && (
                              <img
                                className="propertyImage"
                                src={Parry}
                                alt="property"
                              />
                            )}
                            {item?.input_method?.includes(
                              "Deflect"
                            ) && (
                              <img
                                className="propertyImage"
                                src={Deflect}
                                alt="property"
                              />
                            )}
                          </div>
                          <div style={{ lineHeight: "initial" }}>
                            {item.attack_name}
                          </div>
                        </ListSubheader>
                      }
                    >
                      {item.damage && (
                        <Collapse
                          in={open["damage"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton className="redField">
                            <ListItemText primary="Damage" />
                            {item.damage || "N/A"}
                          </ListItemButton>
                        </Collapse>
                      )}

                      {item.bleed_damage && (
                        <Collapse
                          in={open["damage"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton className="redField">
                            <ListItemText primary="Bleed Damage" />
                            {item.bleed_damage || "N/A"}
                          </ListItemButton>
                        </Collapse>
                      )}

                      {item.stamina_damage && (
                        <Collapse
                          in={open["damage"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton className="redField">
                            <ListItemText primary="Stamina Damage" />
                            {item.stamina_damage || "N/A"}
                          </ListItemButton>
                        </Collapse>
                      )}

                      {item.attack_speed && (
                        <Collapse
                          in={open["speed"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton className="blueField">
                            <ListItemText primary="Speed (ms)" />
                            {item.attack_speed || "N/A"}
                          </ListItemButton>
                        </Collapse>
                      )}

                      {item.stamina_usage && (
                        <Collapse
                          in={open["stamina"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton className="greenField">
                            <ListItemText primary="Stamina Usage" />
                            {item.stamina_usage || "N/A"}
                          </ListItemButton>
                        </Collapse>
                      )}

                      {item.input_method && (
                        <Collapse
                          in={open["input"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton>
                            <ListItemText primary="Input Method" />
                            <div className="CardText">
                              {item.input_method || "N/A"}
                            </div>
                          </ListItemButton>
                        </Collapse>
                      )}

                      {item.input_window && (
                        <Collapse
                          in={open["input"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton>
                            <ListItemText primary="Input Window (ms)" />
                            {item.input_window || "N/A"}
                          </ListItemButton>
                        </Collapse>
                      )}

                      {item.direction && (
                        <Collapse
                          in={open["direction"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton>
                            <ListItemText primary="Direction" />
                            {item.direction || "N/A"}
                          </ListItemButton>
                        </Collapse>
                      )}

                      {item?.hitstun_category && (
                        <Collapse
                          in={open["hitstun"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton>
                            <ListItemText primary="Hitstun" />
                            {item?.hitstun_category || "N/A"}
                          </ListItemButton>
                        </Collapse>
                      )}

                      {item.special_properties && (
                        <Collapse
                          in={open["specialproperties"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton>
                            <ListItemText primary="Properties" />
                            <div className="CardText">
                              {item.special_properties || "N/A"}
                            </div>
                          </ListItemButton>
                        </Collapse>
                      )}

                      {item?.hyperarmor_startup && (
                        <Collapse
                          in={open["specialproperties"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton>
                            <ListItemText primary="Hyperarmor Startup (ms)" />
                            {item?.hyperarmor_startup || "N/A"}
                          </ListItemButton>
                        </Collapse>
                      )}

                      {item?.superior_block_window && (
                        <Collapse
                          in={open["specialproperties"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton>
                            <ListItemText primary="Superior Block Window (ms)" />
                            {item?.superior_block_window || "N/A"}
                          </ListItemButton>
                        </Collapse>
                      )}

                      {item?.pin_duration && (
                        <Collapse
                          in={open["specialproperties"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton>
                            <ListItemText primary="Pin Duration (ms)" />
                            {item?.pin_duration || "N/A"}
                          </ListItemButton>
                        </Collapse>
                      )}

                      {item?.iframe_window && (
                        <Collapse
                          in={open["specialproperties"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton>
                            <ListItemText primary="I-Frame Duration (ms)" />
                            {item?.iframe_window || "N/A"}
                          </ListItemButton>
                        </Collapse>
                      )}

                      {item.earliest_dodge && (
                        <Collapse
                          in={open["misc"]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton>
                            <ListItemText primary="Earliest Dodge (ms)" />
                            {item.earliest_dodge || "N/A"}
                          </ListItemButton>
                        </Collapse>
                      )}

                      <Collapse
                        in={open["recoveries"]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <ListItemButton>
                          <Table
                            className="text-center"
                            style={{backgroundColor: "rgb(55,55,55)"}}
                            striped
                            bordered
                            responsive="sm"
                          >
                            <tbody>
                              <tr>
                                <td colSpan={5}>Recoveries (ms)</td>
                              </tr>
                              <tr className="align-middle">
                                <td> </td>
                                <td>Hit</td>
                                <td>Miss</td>
                                <td>Block</td>
                                <td>Superior Block</td>
                              </tr>
                              <tr className="align-middle">
                                <td>Base</td>
                                <td>{item.hit_recovery_base || "Soon™"}</td>
                                <td>{item.miss_recovery_base || "Soon™"}</td>
                                <td>{item.block_recovery_base || "Soon™"}</td>
                                <td>
                                  {item.superior_block_recovery_base || "Soon™"}
                                </td>
                              </tr>
                              <tr className="align-middle">
                                <td>Guard Swap</td>
                                <td>
                                  {item.hit_block_recovery_guardswap || "Soon™"}
                                </td>
                                <td>
                                  {item.miss_block_recovery_guardswap ||
                                    "Soon™"}
                                </td>
                                <td>
                                  {item.block_recovery_guardswap || "Soon™"}
                                </td>
                                <td>
                                  {item.superior_block_recovery_guardswap ||
                                    "Soon™"}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </ListItemButton>
                      </Collapse>

                      {item.notes && (
                        <ListItemButton
                          className="ListItemNotes"
                          sx={{ backgroundColor: "lightgrey" }}
                          onClick={() => {
                            handleNotes(index1.toString() + index2.toString());
                          }}
                        >
                          <ListItemText primary="Notes" />

                          {isNotesOpened?.includes(
                            index1.toString() + index2.toString()
                          ) ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                      )}
                      {item.notes && (
                        <Collapse
                          in={isNotesOpened?.includes(
                            index1.toString() + index2.toString()
                          )}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton sx={{}}>
                            {item.notes || "N/A"}
                          </ListItemButton>
                        </Collapse>
                      )}
                    </List>
                  </div>
                ))}
              </div>
            )}
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default CharacterAttacks;
