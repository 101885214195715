import React from "react";
import Perk from "./Perk";
import "./CharacterFeatsAndPerks.css";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const Perks = ({ characterId, faction }) => {
  const [perksData, setPerksData] = React.useState({});
  const [collapsible, setCollapsible] = React.useState(true);
  React.useEffect(() => {
    if (characterId) {
      getPerksById(characterId);
    }
  }, []);

  const getPerksById = (id) => {
    fetch(`https://forhonorinfohub.com/api/Perks/Id?characterId=${id}`)
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        myJson.sort(sorting_id);
        let grouped = Object.create(null);
        myJson.forEach(function (a) {
          grouped[a.tier] = grouped[a.tier] || [];
          grouped[a.tier].push(a);
        });

        setPerksData(grouped);
      });
  };

  function sorting_id(a, b) {
    if (a.perk_slot < b.perk_slot) {
      return -1;
    }
    if (a.perk_slot > b.perk_slot) {
      return 1;
    }
    return 0;
  }

  if (perksData === undefined || !perksData) {
    return;
  }

  return (
    <div>
      <div>
        <header className={faction}>
          <h1 className="text-center">Perks</h1>
          <div
            onClick={() => {
              setCollapsible(!collapsible);
            }}
            style={{ cursor: "pointer" }}
          >
            {collapsible ? <ExpandLess sx={{ }} /> : <ExpandMore sx={{ }} />}
          </div>
        </header>
      </div>
      <>
        {collapsible &&
          Object.keys(perksData).map((key, index) => (
            <div className="tier-and-feats" key={index}>
              <div className="perks">
                {perksData[key]?.map((item, key) => (
                  <Perk
                    key={key}
                    name={item.name}
                    category={item.category}
                    description={item.description}
                    perk_slot={item.perk_slot}
                    explanation={item.explanation}
                    image={item.image}
                  />
                ))}
              </div>
            </div>
          ))}
      </>
    </div>
  );
};

export default Perks;
