import axios from "axios";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import "./CharacterResourceTable.css";

import "./../App.css";

const ResourceTable = ({ characterId, faction }) => {
  const [data, setData] = React.useState([]);
  const columns = [
    {
      dataField: "Character",
      text: "Character",
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
      hidden: characterId ? true : false,
      style: {
        width: "8em",
      },
    },
    {
      dataField: "link",
      text: "Link",
      style: {
        width: "1em",
      },
      formatter: (cell, row, rowIndex) => (
        <div>
          <a className="styledLinks" href={row.link} target="_blank" rel="noreferrer">
            {" "}
            Click me!
          </a>
        </div>
      ),
    },
    {
      dataField: "description",
      text: "Description",
      filter: textFilter({placeholder: "Search..."}),
      style: {
        width: "8em",
      },
    },
    {
      dataField: "Tags",
      text: "Tags",
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
      style: {
        width: "8em",
      },
    },
    {
      dataField: "author",
      text: "Author",
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
      style: {
        width: "8em",
      },
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      sortFunc: (a, b, order) => { 
        if (order === 'asc')
        {
          return Date.parse(a) - Date.parse(b)
        }
       else if (order === 'desc') {
          return  Date.parse(b) - Date.parse(a) 
        }
      },
      filter: textFilter({placeholder: "Search..."}),
      style: {
        width: "4em",
      },
    },
    {
      dataField: "notes",
      text: "Notes",
      style: {
        width: "12em",
      },
    },
  ];
  const getData = async () => {
    const url = characterId
      ? `https://forhonorinfohub.com/api/Resources/Id?characterId=${characterId}`
      : "https://forhonorinfohub.com/api/Resources";
    const { data } = await axios.get(url);
    setData(data);
  };
  React.useEffect(() => {
    getData();
  }, []);
  const [collapsible, setCollapsible] = React.useState(false);
  return (
    <div>
      <div>
        <header className={faction}>
          <h1 className="text-center">Community Resources</h1>
          <div
            onClick={() => {
              setCollapsible(!collapsible);
            }}
            style={{ cursor: "pointer" }}
          >
            {collapsible ? <ExpandLess sx={{ }} /> : <ExpandMore sx={{ }} />}
          </div>
        </header>
      </div>
      {collapsible && (
        <div className="resourcesTable">
          <div className="table-responsive">
          <BootstrapTable
            keyField="ID"
            data={data}
            columns={columns}
            striped
            filter={filterFactory()}
            filterPosition="top"
            noDataIndication="There is no data that matches those filters."
          />
        </div>
        </div>
      )}
    </div>
  );
};

export default ResourceTable;
