import axios from "axios";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { MultiSelect } from "react-multi-select-component";

import "./CharacterExecutionTable.css";

import "./../App.css";

const CharacterSharedExecutionTable = ({ characterId }) => {
  const [data, setData] = React.useState([]);
  const [filteredData,setFilteredData]=React.useState([])
  const [hpFilters, setHpFilters] = React.useState([]);

  const hpData = [];
  data?.map((characterHp) => {
    !hpData.includes(characterHp?.hp_gain) && hpData.push(characterHp?.hp_gain);
  });
  let selectHpData = [];
  hpData?.map((characterHp, index) => {
    const temp = { label: characterHp, value: characterHp };
    selectHpData.push(temp);
  });

  selectHpData = sortByKey(selectHpData, "label");
  function sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
  React.useEffect(()=>{
    const tempFilters=[]
    hpFilters?.map((filter)=>{
      data?.map((characterInfo)=>filter?.value === characterInfo?.hp_gain && tempFilters.push(characterInfo))

    })
    setFilteredData(tempFilters)


  },[hpFilters])
  const hpHeader = (column) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" ,maxWidth:'8rem'}}>
        <div style={{marginBottom:'1rem',display:"flex",alignItems:'flex-end',justifyContent:'center'}}>
        {column.text}
          </div>
        <div style={{marginBottom:'-3.5rem', maxWidth: "7rem"}}>
          <MultiSelect
            options={selectHpData}
            value={hpFilters}
            onChange={setHpFilters}
            labelledBy="Select"
          />
        </div>
      </div>
    );
  };
  const columns = [
    {
      dataField: "ID",
      text: "ID",
     hidden:true
    },
    {
      dataField: "name",
      text: "Execution Name",
      headerAlign: "center",
      sort: true,

      filter: textFilter({placeholder: "Search..."}),
      style: {
        width: "12em",
        textAlign: "center",
      },
    },
    {
      dataField: "kill_time",
      text: "Kill Time (ms)",
      headerAlign: "center",
      sort: true,

      style: {
        width: "8.9em",
        textAlign: "center",
      },
    },
    {
      dataField: "user_duration",
      text: "User Duration (ms)",
      headerAlign: "center",
      sort: true,

      style: {
        width: "11.3em",
        textAlign: "center",
      },
    },
    {
      dataField: "victim_duration",
      text: "Victim Duration (ms)",
      headerAlign: "center",
      sort: true,

      style: {
        width: "12.3em",
        textAlign: "center",
      },
    },
    {
      dataField: "hp_gain",
      text: "HP Gain",
      headerAlign: "center",
      headerFormatter: hpHeader,
      style: {
        width: "7em",
        textAlign: "center",
      },
    },
    // {
    //   dataField: "in_lock",
    //   text: "In Lock?",
    //   headerAlign: "center",
    //   sort: true,

    //   style: {
    //     width: "6.3em",
    //     textAlign: "center",
    //   },
    // },
    {
      dataField: "can_ledge",
      text: "Ragdolls?",
      headerAlign: "center",
      sort: true,

      style: {
        width: "6.9em",
        textAlign: "center",
      },
    },
    {
      dataField: "availability",
      text: "Availability",
      headerAlign: "center",
      sort: true,
      style: {
        width: "18em",
        textAlign: "center",
      },
    },
    {
      dataField: "notes",
      text: "Notes",
      sort: true,
      headerAlign: "center",
      style: {
        width: "fit-content",
        textAlign: "center",
      },
    },
  ];
  const getData = async () => {
    const url = "https://forhonorinfohub.com/api/SharedExecutions";
    const { data } = await axios.get(url);
    setData(data);
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <div className="executionsTable">
      <div className="table-responsive">
        <BootstrapTable
          keyField="ID"
          filterPosition="top"
          data={Boolean(filteredData.length)? filteredData:data}
          columns={columns}
          striped
          filter={filterFactory()}
          noDataIndication="There is no data that matches those filters."
          id={`${(Boolean(filteredData?.length) && filteredData?.length <5) && "lessFilteredItems"}`}
        />
      </div>
    </div>
  );
};

export default CharacterSharedExecutionTable;
