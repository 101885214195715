import React from "react";
import Feat from "./Feat";
import "./CharacterFeatsAndPerks.css";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const Feats = ({ characterId, faction }) => {
  const [featsData, setFeatsData] = React.useState({});
  const [collapsible, setCollapsible] = React.useState(true);

  React.useEffect(() => {
    if (characterId) {
      getFeatsById(characterId);
    }
  }, []);

  const getFeatsById = (id) => {
    fetch(`https://forhonorinfohub.com/api/Feats/Id?characterId=${id}`)
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        let grouped = Object.create(null);
        myJson.forEach(function (a) {
          grouped[a.tier] = grouped[a.tier] || [];
          grouped[a.tier].push(a);
        });
        const sorted = Object.keys(grouped)?.map((key) => key);

        for (let i = 1; i < sorted.length + 1; i++) {
          grouped[i].sort(sorting_id);
        }

        setFeatsData(grouped);
      });
  };
  if (featsData === undefined || !featsData) {
    return;
  }
  function sorting_id(a, b) {
    if (a.feat_slot < b.feat_slot) {
      return -1;
    }
    if (a.feat_slot > b.feat_slot) {
      return 1;
    }
    return 0;
  }
  return (
    <div>
      <>
        <div>
          <header className={faction}>
            <h1 className="text-center">Feats</h1>
            <div
              onClick={() => {
                setCollapsible(!collapsible);
              }}
              style={{ cursor: "pointer" }}
            >
              {collapsible ? <ExpandLess sx={{ }} /> : <ExpandMore sx={{ }} />}
            </div>
          </header>
        </div>
        {collapsible && (
          <div>
            {Object.keys(featsData).map((key, index) => (
              <div className="tier-and-feats" key={index}>
                <div id={"tier" + key} className={"tier" + key}>
                  Tier {key}
                </div>
                <div className="feats">
                  {featsData[key]?.map((item, key) => (
                    <Feat
                      key={key}
                      name={item.name}
                      tier={"★".repeat(item.tier)}
                      category={item.category}
                      activation_time={item.activation_time}
                      cooldown={item.cooldown}
                      description={item.description}
                      recovery_time={item.recovery_time}
                      explanation={item.explanation}
                      image={item?.image}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    </div>
  );
};

export default Feats;
