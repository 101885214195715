import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

import "./../App.css";

const CharacterAttacksTable = ({ characterId }) => {
  const [data, setData] = React.useState([]);
  const columns = [
    {
      dataField: "name",
      text: "Character",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "attack_name",
      text: "Attack Name",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "attack_category",
      text: "Attack Category",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "input_method",
      text: "Input Method",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "direction",
      text: "Direction",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "hitstun_category",
      text: "Hitstun Category",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "special_properties",
      text: "Properties",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "attack_speed",
      text: "Attack Speed (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      sortFunc: (a, b, order, dataField) => {
        if (order === 'asc' || !order) {
            return b.localeCompare(a, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true});
            }
            return a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true});
            },
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "damage",
      text: "Damage",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "bleed_damage",
      text: "Bleed Damage",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "stamina_damage",
      text: "Stamina Damage",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "stamina_usage",
      text: "Stamina Usage",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "earliest_dodge",
      text: "Earliest Dodge Window (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "input_window",
      text: "Input Window (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      sortFunc: (a, b, order, dataField) => {
        if (order === 'asc' || !order) {
            return b.localeCompare(a, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true});
            }
            return a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true});
            },
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "iframe_window",
      text: "I-Frame Duration (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      sortFunc: (a, b, order, dataField) => {
        if (order === 'asc' || !order) {
            return b.localeCompare(a, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true});
            }
            return a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true});
            },
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "hyperarmor_startup",
      text: "Hyperarmor Startup (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "superior_block_duration",
      text: "Superior Block Duration (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      sortFunc: (a, b, order, dataField) => {
        if (order === 'asc' || !order) {
            return b.localeCompare(a, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true});
            }
            return a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true});
            },
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "pin_duration",
      text: "Pin Duration (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      sortFunc: (a, b, order, dataField) => {
        if (order === 'asc' || !order) {
            return b.localeCompare(a, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true});
            }
            return a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true});
            },
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "hit_recovery_base",
      text: "Hit Recovery Base (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "hit_recovery_guardswap",
      text: "Hit Recovery Guardswap (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "block_recovery_base",
      text: "Block Recovery Base (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "block_recovery_guardswap",
      text: "Block Recovery Guardswap (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "miss_recovery_base",
      text: "Miss Recovery Base (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "miss_recovery_guardswap",
      text: "Miss Recovery Guardswap (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "superior_block_recovery_base",
      text: "Superior Block Recovery Base (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
    {
      dataField: "superior_block_recovery_guardswap",
      text: "Superior Block Recovery Guardswap (ms)",
      headerAlign: "center",
      style: {
        textAlign: "center",
      },
      sort: true,
      filter: textFilter({placeholder: "Search..."}),
    },
  ];
  const getData = async () => {
    const url = "https://forhonorinfohub.com/api/CharacterAttackData";
    const { data } = await axios.get(url);
    setData(data);
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <BootstrapTable
        keyField="ID"
        data={data}
        columns={columns}
        striped
        filter={filterFactory()}
        filterPosition="top"
        noDataIndication="There is no data that matches those filters."
      />
    </div>
  );
};

export default CharacterAttacksTable;
