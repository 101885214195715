import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CharacterSharedExecutionTable from "./../components/CharacterSharedExecutionTable";
import ExecutionTable from "./../components/ExecutionTable";
import CharacterPunishTable from "../components/CharacterPunishTable";
import CharacterResourceTable from "../components/CharacterResourceTable";
import CircularProgress from "@mui/material/CircularProgress";
import CharacterAttacks from "./../components/CharacterAttacks.jsx";
import CharacterMoveset from "./../components/CharacterMoveset.jsx";

import Feats from "../components/CharacterFeats";
import Perks from "../components/CharacterPerks";

import Offcanvas from "react-bootstrap/Offcanvas";
import Fab from "@mui/material/Fab";
import MenuIcon from '@mui/icons-material/Menu';

import "./CharacterPage.css";

const styledLink = {
  textDecoration: "none",
  color: "var(--bs-light)",
};

const fabStyle = {
  position: "fixed",
  bottom: "3%",
  left: "2%"
};

function NavPane({ name, ...props }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const toggleShow = () => {
    setShow((s) => !s);
  };

  return (
    <>
      <Fab style={fabStyle} onClick={toggleShow}>
        <MenuIcon />
      </Fab>
      <Offcanvas
        show={show}
        scroll={true}
        restoreFocus={false}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Jump to...</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <a style={styledLink}onClick={() => {
              toggleShow();
              
            }} href="#CharacterNotes">
            Character Notes
          </a>
          <br />
          <br />
          <a style={styledLink} onClick={() => {
              toggleShow();
              
            }} href="#CharacterStats">
            Character Stats
          </a>
          <br />
          <br />
          <a
            style={styledLink}
            onClick={() => {
              toggleShow();
              
            }}
            href="#AttackChains"
          >
            Attack Chains
          </a>
          <br />
          <br />
          <details>
            <summary>Attacks Types</summary>
              <a style={styledLink} onClick={() => {
              toggleShow();
              
            }} href="#Basic Attacks">
                Basic Attacks
                <br />
              </a>
              <a
                style={styledLink}
                onClick={() => {
                  toggleShow();
                  
                }}
                href="#Running Attacks"
              >
                Running Attacks
                <br />
              </a>
              <a style={styledLink} onClick={() => {
              toggleShow();
              
            }} href="#Zone Attacks">
                Zone Attacks
                <br />
              </a>
            {props.characterAttacks?.includes("Confirmed Attack") && (
                <a
                  style={styledLink}
                  onClick={() => {
                    toggleShow();
                    
                  }}
                  href="#Confirmed Attacks"
                >
                  Confirmed Attacks
                  <br />
                </a>
            )}

            {props.characterAttacks?.includes("Riposte") && (
                <a style={styledLink} onClick={() => {
              toggleShow();
              
            }} href="#Ripostes">
                  Ripostes
                  <br />
                </a>
            )}

            {props.characterAttacks?.includes("Forward Dodge Attack") && (
                <a
                  style={styledLink}
                  onClick={() => {
                    toggleShow();
                    
                  }}
                  href="#Forward Dodge Attacks"
                >
                  Forward Dodge Attacks
                  <br />
                </a>
            )}

            {props.characterAttacks?.includes("Side Dodge Attack") && (
                <a
                  style={styledLink}
                  onClick={() => {
                    toggleShow();
                    
                  }}
                  href="#Side Dodge Attacks"
                >
                  Side Dodge Attacks
                  <br />
                </a>
            )}

            {props.characterAttacks?.includes("Chain Bash") && (
                <a style={styledLink} onClick={() => {
              toggleShow();
              
            }} href="#Chain Bashes">
                  Chain Bashes
                  <br />
                </a>
            )}

            {props.characterAttacks?.includes("Neutral Bash") && (
                <a
                  style={styledLink}
                  onClick={() => {
                    toggleShow();
                    
                  }} 
                  href="#Neutral Bashes"
                >
                  Neutral Bashes
                  <br />
                </a>
            )}

            {props.characterAttacks?.includes("Stance Attack") && (
                <a
                  style={styledLink}
                  onClick={() => {
                    toggleShow();
                    
                  }}
                  href="#Stance Attacks"
                >
                  Stance Attacks
                  <br />
                </a>
            )}

            {props.characterAttacks?.includes("Special Moves") && (
                <a
                  style={styledLink}
                  onClick={() => {
                    toggleShow();
                    
                  }}
                  href="#Special Moves"
                >
                  Special Moves
                  <br />
                </a>
            )}
          </details>
          <br />
          {props?.isCharacterStance && (
              <a style={styledLink} onClick={() => {
              toggleShow();
              
            }} href="#Stance">
                Stance
                <br />
                <br />
              </a>
          )}
          <a style={styledLink} onClick={() => {
              toggleShow();
              
            }} href="#MaxPunishes">
            Max Punishes Table
          </a>
          <br />
          <br />
          <a style={styledLink} onClick={() => {
              toggleShow();
              
            }} href="#Feats">
            Feats
          </a>
          <br />
          <br />
          <a style={styledLink} onClick={() => {
              toggleShow();
              
            }} href="#Perks">
            Perks
          </a>
          <br />
          <br />
          <a style={styledLink} onClick={() => {
              toggleShow();
              
            }} href="#Executions">
            Unique Executions Table
          </a>
          <br />
          <br />
          <a style={styledLink} onClick={() => {
              toggleShow();
              
            }} href="#SharedExecutions">
            Shared Executions Table
          </a>
          <br />
          <br />
          <br />
          <br />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export const CharacterPage = () => {
  const location = useLocation();
  const URLParams = new URLSearchParams(location.search);

  const characterId = URLParams.get("");

  const [characterInfo, setCharacterInfo] = useState([]);
  const [characterName, setCharacterName] = useState("");
  const [characterFaction, setCharacterFaction] = useState("");
  const [characterAttackChains, setCharacterAttackChains] = useState("");
  const [characterAttacks, setCharacterAttacks] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [characterStance, setCharacterStance] = React.useState();
  React.useEffect(() => {
    getCharacterInfo();
    getCharacterName();
    getCharacterFaction();
    getCharacterAttackChains();
    getCharacterAttacks();
    getCharacterStance();
  }, []);
  const getCharacterStance = () => {
    fetch(
      `https://forhonorinfohub.com/api/CharacterStance?characterId=${characterId}`
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        setCharacterStance(myJson[0]);
      });
  };
  const getCharacterName = () => {
    fetch(
      `https://forhonorinfohub.com/api/CharacterName?characterId=${characterId}`
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        setCharacterName(myJson[0].name);
      });
  };

  const getCharacterInfo = () => {
    fetch(
      `https://forhonorinfohub.com/api/CharacterInfo?characterId=${characterId}`
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        setCharacterInfo(myJson[0]);
      });
  };

  const getCharacterFaction = () => {
    fetch(
      `https://forhonorinfohub.com/api/CharacterFaction?characterId=${characterId}`
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        setCharacterFaction(myJson[0].faction);
      });
  };

  const getCharacterAttackChains = () => {
    fetch(
      `https://forhonorinfohub.com/api/CharacterAttackChains?characterId=${characterId}`
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        setCharacterAttackChains(myJson[0]?.AttackChains);
      });
  };

  const getCharacterAttacks = () => {
    fetch(
      `https://forhonorinfohub.com/api/CharacterAttacks?characterId=${characterId}`
    )
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        let grouped = Object.create(null);
        myJson.forEach(function (a) {
          grouped[a.attack_category] = grouped[a.attack_category] || [];
          grouped[a.attack_category].push(a);
        });
        setCharacterAttacks(grouped);
      });
  };

  if (!characterId) {
    return (
      <div>
        <center>
          <h1>No valid character has been specified.</h1>
        </center>
      </div>
    );
  }
  return (
    <div>

      {!characterInfo ||
      !characterAttacks ||
      !characterAttackChains.length > 0 ||
      !characterFaction.length > 0 ||
      !characterName.length > 0 ? (
        <CircularProgress className="circular-progress" />
      ) : (
        <div id="top">
          {/* {console.log("characterAttacks", characterAttacks)} */}
          <NavPane
            placement={"start"}
            characterAttacks={Object.keys(characterAttacks)}
            isCharacterStance={Boolean(characterStance)}
          />
          <center>
            <header className={characterFaction}>
              <h1 className="text-center">
                {characterName || "N/A"}
              </h1>
            </header>
            <Card sx={{ maxWidth: 875, margin: 2  }} id="CharacterNotes">
              <CardContent>
                <Typography variant="h4" component="div">
                  Character Notes
                </Typography>

                <Typography variant="body">{characterInfo.notes}</Typography>
              </CardContent>
            </Card>
            <div id="CharacterStats">
            <CharacterMoveset
              characterInfo={characterInfo}
              id="CharacterStats"
              style={{ paddingTop: "40px !important" }}
            />
            </div>

            <CharacterResourceTable
              characterId={characterId}
              faction={characterFaction}
            />

            <div id="AttackChains" />
            <header className={characterFaction}>
              <h1>Attack Chains</h1>
            </header>
            <Card sx={{ maxWidth: 675, margin: 2 }}>
              <CardContent>
                <Typography variant="h3" component="div"></Typography>

                <Typography variant="body">{characterAttackChains}</Typography>
              </CardContent>
            </Card>
          </center>
          <div id="AttackFilters" />
          <CharacterAttacks
            characterAttacks={{
              "Basic Attacks": characterAttacks["Basic Attack"],
              "Running Attacks": characterAttacks["Running Attack"],
              "Zone Attacks": characterAttacks["Zone Attack"],
              "Confirmed Attacks": characterAttacks["Confirmed Attack"],
              Ripostes: characterAttacks["Riposte"],
              "Forward Dodge Attacks": characterAttacks["Forward Dodge Attack"],
              "Side Dodge Attacks": characterAttacks["Side Dodge Attack"],
              "Chain Bashes": characterAttacks["Chain Bash"],
              "Neutral Bashes": characterAttacks["Neutral Bash"],
              "Special Moves": characterAttacks["Special Moves"],
              "Stance Attacks": characterAttacks["Stance Attack"],
            }}
            faction={characterFaction}
            characterId={characterId}
          />

          <div id="MaxPunishes" />
          <CharacterPunishTable
            characterId={characterId}
            faction={characterFaction}
          />

          <div id="Feats" />
          <Feats characterId={characterId} faction={characterFaction} />

          <div id="Perks" />
          <Perks characterId={characterId} faction={characterFaction} />

          <div id="Executions" />
          <header className={characterFaction}>
            <h1>Character Specific Executions</h1>
          </header>

          <ExecutionTable characterId={characterId} />

          <div id="SharedExecutions" />
          <header className={characterFaction}>
            <h1>Shared Executions</h1>
          </header>
          <CharacterSharedExecutionTable characterId={characterId} />
        </div>
      )}
    </div>
  );
};

export default CharacterPage;
