import React from "react";
import "./Feat.css";

import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ChatIcon from "@mui/icons-material/ChatSharp";

const statisticStyle = {
  marginTop: "0px",
  marginBottom: "0px",
  marginRight: "25%",
  marginLeft: "25%",
};

const headerStyle = {
  marginTop: "10px",
  marginBottom: "0px",
};

const Feat = ({
  name,
  tier,
  description,
  cooldown,
  activation_time,
  recovery_time,
  explanation,
  image
}) => {
  return (
    <div className="feat">
      
      <div className="feat-name-and-description">
        <div className="feat-name">
          <div>{name}</div>
          <div>{tier}</div>
          <hr style={headerStyle} />
        </div>
        <div className="feat-description">
        {image && <img src={"feats/"+image+".png"} alt="featImage" className='featImage' draggable="false"/>}
          <div className="feat-description-explanation">{description}</div>
        </div>
      </div>
      <div className="feat-statistic-container">

        {cooldown !== null ? (
          <div>
            <hr style={statisticStyle} />
            <div className="feat-statistic-heading">Cooldown</div>
            <div>{cooldown ? cooldown + " seconds" : "N/A"}</div>
          </div>
        ) : (
          ""
        )}
        {activation_time !== null ? (
          <div>
            <hr style={statisticStyle} />
            <div className="feat-statistic-heading">Activation Time</div>
            <div>{activation_time ? activation_time + "ms" : "N/A"}</div>
          </div>
        ) : (
          ""
        )}
        {recovery_time !== null ? (
          <div>
            <hr style={statisticStyle} />
            <div className="feat-statistic-heading">Recovery Time</div>
            <div>{recovery_time ? recovery_time + "ms" : "N/A"}</div>
          </div>
        ) : (
          ""
        )}
        {recovery_time !== null ||
        activation_time !== null ||
        cooldown !== null ? (
          <div>
            <hr style={statisticStyle} />
          </div>
        ) : (
          ""
        )}
      </div>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="top"
        overlay={
          <Popover>
            <Popover.Header as="h3">{"Feat Assessment"}</Popover.Header>
            <Popover.Body>
              {explanation ||
                "We are still assessing this feat - it's coming Soon™️"}
            </Popover.Body>
          </Popover>
        }
      >
        <center>
          <ChatIcon fontSize="large" sx={{ marginBottom: "0.75rem" }} />
        </center>
      </OverlayTrigger>
    </div>
  );
};

export default Feat;
